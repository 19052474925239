var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { BaseProvider } from '../../core/esl-media-provider';
import { HTMLMediaProvider } from './media-provider';
/**
 * Simple Video API provider for {@link ESLMedia}
 * @author Yuliya Adamskaya
 */
let VideoProvider = class VideoProvider extends HTMLMediaProvider {
    createElement() {
        const el = document.createElement('video');
        el.src = this.src;
        return el;
    }
    applyElementSettings(cfg) {
        super.applyElementSettings(cfg);
        this._el.playsInline = cfg.playsInline;
        this._el.disablePictureInPicture = cfg.disablePictureInPicture;
    }
    get defaultAspectRatio() {
        return this._el.videoWidth / this._el.videoHeight;
    }
};
VideoProvider.providerName = 'video';
VideoProvider.urlPattern = /\.(mp4|webm|ogv|mov)(\?|$)/;
VideoProvider = __decorate([
    BaseProvider.register
], VideoProvider);
export { VideoProvider };
