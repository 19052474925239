/**
 * Event class for {@link ESLMedia} hooks
 * (cancelable events dispatched during ESLMedia lifecycle)
 */
export class ESLMediaHookEvent extends Event {
    constructor(type, init) {
        super(type, {
            bubbles: true,
            cancelable: true,
            composed: true
        });
        Object.assign(this, init);
    }
}
