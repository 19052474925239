const DEFAULT_SCROLL_TYPE = 'negative';
/** Checks if the element in a RTL direction context */
export const isRTL = (el) => getComputedStyle(el || document.body).direction === 'rtl';
/** @deprecated scroll type is now consistent and always returns a `negative` value */
export const testRTLScrollType = () => {
    return DEFAULT_SCROLL_TYPE;
};
/** @deprecated scroll type is now consistent and always returns a `negative` value */
export const RTLScroll = {
    /** @returns RTL scroll type (lazy, memoized) */
    get type() {
        return DEFAULT_SCROLL_TYPE;
    }
};
export const normalizeScrollLeft = (el, value = null, isRtl = isRTL(el)) => {
    value = (value === null) ? el.scrollLeft : value;
    return isRtl ? el.scrollWidth - el.clientWidth + value : value;
};
