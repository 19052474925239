var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ESLMediaControlMixin_1;
import { ESLMixinElement } from '../../esl-mixin-element/core';
import { ESLTraversingQuery } from '../../esl-traversing-query/core/esl-traversing-query';
import { listen, memoize } from '../../esl-utils/decorators';
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { evaluate } from '../../esl-utils/misc/format';
let ESLMediaControlMixin = ESLMediaControlMixin_1 = class ESLMediaControlMixin extends ESLMixinElement {
    get config() {
        const attrVal = this.$$attr(ESLMediaControlMixin_1.is) || '{}';
        const userConfig = attrVal.trim().startsWith('{') ? evaluate(attrVal, {}) : { target: attrVal };
        return Object.assign(Object.assign({}, ESLMediaControlMixin_1.DEFAULT_CONFIG), userConfig);
    }
    set config(value) {
        const serialized = typeof value === 'string' ? value : JSON.stringify(value);
        this.$$attr(ESLMediaControlMixin_1.is, serialized);
    }
    get $target() {
        if (!this.config.target)
            return null;
        return ESLTraversingQuery.first(this.config.target, this.$host);
    }
    attributeChangedCallback() {
        memoize.clear(this, 'config');
        this.$$on(this.onStateChange);
    }
    onClick() {
        const { $target, config } = this;
        if (!$target || !config.action)
            return;
        if (config.action in $target && typeof $target[config.action] === 'function')
            $target[config.action]();
    }
    onStateChange(e) {
        this.$$cls(this.config.activeCls, e.type === 'esl:media:play');
    }
};
ESLMediaControlMixin.is = 'esl-media-control';
ESLMediaControlMixin.DEFAULT_CONFIG = {
    action: 'toggle'
};
__decorate([
    memoize()
], ESLMediaControlMixin.prototype, "config", null);
__decorate([
    listen('click')
], ESLMediaControlMixin.prototype, "onClick", null);
__decorate([
    listen({
        event: 'esl:media:play esl:media:paused esl:media:ended',
        target: (control) => control.$target,
        condition: (control) => !!control.config.activeCls
    })
], ESLMediaControlMixin.prototype, "onStateChange", null);
ESLMediaControlMixin = ESLMediaControlMixin_1 = __decorate([
    ExportNs('MediaControlMixin')
], ESLMediaControlMixin);
export { ESLMediaControlMixin };
