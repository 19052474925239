var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { pick } from '../../esl-utils/misc/object';
import { DelayedTask } from '../../esl-utils/async/delayed-task';
import { ESLMediaProviderRegistry } from './esl-media-registry';
export var PlayerStates;
(function (PlayerStates) {
    PlayerStates[PlayerStates["BUFFERING"] = 3] = "BUFFERING";
    PlayerStates[PlayerStates["ENDED"] = 0] = "ENDED";
    PlayerStates[PlayerStates["PAUSED"] = 2] = "PAUSED";
    PlayerStates[PlayerStates["PLAYING"] = 1] = "PLAYING";
    PlayerStates[PlayerStates["UNSTARTED"] = -1] = "UNSTARTED";
    PlayerStates[PlayerStates["VIDEO_CUED"] = 5] = "VIDEO_CUED";
    PlayerStates[PlayerStates["UNINITIALIZED"] = -2] = "UNINITIALIZED";
})(PlayerStates || (PlayerStates = {}));
export const MediaProviderConfigKeys = [
    'loop',
    'muted',
    'controls',
    'autoplay',
    'title',
    'preload',
    'playsInline',
    'disablePictureInPicture',
    'startTime',
    'focusable'
];
/**
 * BaseProvider class for media API providers
 * @author Alexey Stsefanovich (ala'n), Yuliya Adamskaya, Natallia Harshunova
 */
export class BaseProvider {
    static parseUrl(url) {
        return null;
    }
    static parseConfig(component) {
        const { mediaSrc, mediaId } = component;
        const config = pick(component, MediaProviderConfigKeys);
        if (mediaSrc)
            Object.assign(config, { mediaSrc });
        if (mediaId)
            Object.assign(config, { mediaId });
        return config;
    }
    constructor(component, config) {
        this._cmdMng = new DelayedTask();
        this.config = config;
        this.component = component;
        // Other-vice browser will handle play-in-viewport it differently
        if (this.component.playInViewport)
            this.config.autoplay = false;
        // If autoplay is enabled - dispatch before play event to ensure it is allowed
        if (this.config.autoplay) {
            this.config.autoplay = this.component._onBeforePlay('initial');
        }
        this._lastCmdType = this.config.autoplay ? 'play' : 'pause';
    }
    /** Wraps _ready promise */
    get ready() {
        if (!this._ready) {
            const res = Promise.reject('Not Initialized');
            res.catch((e) => console.log('Rejected Media Operation: ', e));
            return res;
        }
        return this._ready;
    }
    /** Unbind the provider instance from the component */
    unbind() {
        Array.from(this.component.querySelectorAll('.esl-media-inner'))
            .forEach((el) => el.parentNode && el.parentNode.removeChild(el));
    }
    /** Provider name */
    get name() {
        return this.constructor.providerName;
    }
    /** Set focus to the inner content */
    focus() {
        var _a;
        (_a = this._el) === null || _a === void 0 ? void 0 : _a.focus();
    }
    onConfigChange(param, value) {
        this.config[param] = value;
    }
    /** Set size for inner content */
    setSize(width, height) {
        if (!this._el)
            return;
        this._el.style.setProperty('width', width === 'auto' ? null : `${width}px`);
        this._el.style.setProperty('height', height === 'auto' ? null : `${height}px`);
    }
    setAspectRatio(aspectRatio) {
        var _a;
        (_a = this._el) === null || _a === void 0 ? void 0 : _a.style.setProperty('aspect-ratio', aspectRatio > 0 ? `${aspectRatio}` : null);
    }
    /** Executes onConfigChange action when api is ready */
    onSafeConfigChange(param, value) {
        this.ready.then(() => this.onConfigChange(param, value));
    }
    /** Executes seekTo action when api is ready */
    safeSeekTo(pos) {
        return this.ready.then(() => this.seekTo(pos));
    }
    /** Executes play when api is ready */
    safePlay() {
        return __awaiter(this, arguments, void 0, function* (system = false) {
            yield this.ready;
            this._cmdMng.put(() => {
                if (!this.component._onBeforePlay(system ? 'system' : 'user'))
                    return;
                this._lastCmdType = 'play';
                this.play();
            }, 0);
        });
    }
    /** Executes pause when api is ready */
    safePause() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ready;
            this._cmdMng.put(() => {
                this._lastCmdType = 'pause';
                this.pause();
            }, 0);
        });
    }
    /**
     * Executes stop when api is ready
     * @returns Promise
     */
    safeStop() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ready;
            this._cmdMng.put(() => {
                this._lastCmdType = 'pause';
                this.stop();
            }, 0);
        });
    }
    /** @returns last requested command type */
    get lastCommand() {
        return this._lastCmdType;
    }
    static register(provider) {
        provider = provider || this;
        if (provider === BaseProvider)
            throw new Error('`BaseProvider` can\'t be registered.');
        if (!((provider === null || provider === void 0 ? void 0 : provider.prototype) instanceof BaseProvider))
            throw new Error('Provider should be instanceof `BaseProvider`');
        ESLMediaProviderRegistry.instance.register(provider);
    }
}
