import { SyntheticEventTarget } from '../../esl-utils/dom/events/target';
import { ESLMediaRegistryEvent } from './esl-media-registry.event';
let evRegistryInstance = null;
/**
 * ESLMediaProviderRegistry class to store media API providers
 * @author Yuliya Adamskaya, Natallia Harshunova
 */
export class ESLMediaProviderRegistry extends SyntheticEventTarget {
    constructor() {
        super(...arguments);
        this.providersMap = new Map();
    }
    static get instance() {
        if (!evRegistryInstance) {
            evRegistryInstance = new ESLMediaProviderRegistry();
        }
        return evRegistryInstance;
    }
    /** List of registered providers */
    get providers() {
        return Array.from(this.providersMap.values());
    }
    /** Register provider */
    register(provider) {
        if (!provider.providerName)
            throw new Error('Provider should have a name');
        this.providersMap.set(provider.providerName, provider);
        this.dispatchEvent(new ESLMediaRegistryEvent(this, provider));
    }
    /** Check that provider is registered for passed name */
    has(name) {
        return this.providersMap.has(name);
    }
    /** Find provider by name */
    findByName(name) {
        if (!name || name === 'auto')
            return null;
        return this.providersMap.get(name.toLowerCase()) || null;
    }
    /** Create provider instance for passed ESLMedia instance */
    createFor(media) {
        return this.createByType(media) || this.createBySrc(media);
    }
    /** Create provider instance for passed ESLMedia instance via provider name */
    createByType(media) {
        const provider = this.findByName(media.mediaType);
        return provider ? ESLMediaProviderRegistry._create(provider, media) : null;
    }
    /** Create provider instance for passed ESLMedia instance via url */
    createBySrc(media) {
        for (const provider of this.providers.reverse()) {
            const cfg = provider.parseUrl(media.mediaSrc);
            if (cfg)
                return ESLMediaProviderRegistry._create(provider, media, cfg);
        }
        return null;
    }
    /** Create provider instance for passed configuration */
    static _create(provider, media, cfg = provider.parseUrl(media.mediaSrc)) {
        const config = Object.assign({}, cfg || {}, provider.parseConfig(media));
        const instance = new provider(media, config);
        if (instance) {
            instance.bind();
            console.debug('[ESL] Media provider created', instance);
        }
        return instance;
    }
}
