var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { isAndroid, isBlink, isEdgeHTML, isGecko, isIE, isMobile, isMobileIOS, isMobileSafari, isSafari, isTouchDevice, isWebkit } from '../../../esl-utils/environment/device-detector';
import { ExportNs } from '../../../esl-utils/environment/export-ns';
const shortcuts = new Map();
/**
 * Static shortcuts preprocessor. Used to store device related shortcuts.
 * @author Alexey Stsefanovich (ala'n)
 *
 * @implements IMediaQueryPreprocessor statically
 */
let ESLEnvShortcuts = class ESLEnvShortcuts {
    /**
     * Add mapping
     * @param shortcut - term to find in query
     * @param value - media query string or boolean result (that represents `all` or `not all` conditions)
     */
    static add(shortcut, value) {
        if (!['boolean', 'string'].includes(typeof value))
            value = false;
        shortcuts.set(shortcut.toLowerCase(), value);
    }
    /** Remove mapping for passed shortcut term */
    static remove(shortcut) {
        return shortcuts.delete(shortcut.toLowerCase());
    }
    /** Replaces shortcut to registered result */
    static process(match) {
        if (shortcuts.has(match))
            return shortcuts.get(match);
    }
};
// For debug purposes
ESLEnvShortcuts._shortcuts = shortcuts;
ESLEnvShortcuts = __decorate([
    ExportNs('EnvShortcuts')
], ESLEnvShortcuts);
export { ESLEnvShortcuts };
// Touch check
ESLEnvShortcuts.add('touch', isTouchDevice);
// Basic device type shortcuts
ESLEnvShortcuts.add('mobile', isMobile);
ESLEnvShortcuts.add('desktop', !isMobile);
ESLEnvShortcuts.add('android', isAndroid);
ESLEnvShortcuts.add('ios', isMobileIOS);
// Basic browser shortcuts
ESLEnvShortcuts.add('ie', isIE);
ESLEnvShortcuts.add('edge', isEdgeHTML);
ESLEnvShortcuts.add('gecko', isGecko);
ESLEnvShortcuts.add('webkit', isWebkit);
ESLEnvShortcuts.add('blink', isBlink);
ESLEnvShortcuts.add('safari', isSafari);
ESLEnvShortcuts.add('safari-ios', isMobileSafari);
