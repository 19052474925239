var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLMixinElement } from '@exadel/esl/modules/esl-mixin-element/core';
import { ready } from '@exadel/esl/modules/esl-utils/decorators';
import { ESLEventUtils } from '@exadel/esl/modules/esl-utils/dom/events';
import { Rect } from '@exadel/esl/modules/esl-utils/dom/rect';
import { getWindowRect } from '@exadel/esl/modules/esl-utils/dom/window';
import { getViewportForEl } from '@exadel/esl/modules/esl-utils/dom/scroll';
export class ESLDemoDistanceToViewportAlert extends ESLMixinElement {
    connectedCallback() {
        super.connectedCallback();
        this.showAlert();
    }
    calculateDistance() {
        let topDistance;
        let bottomDistance;
        const elementRect = Rect.from(this.$host.getBoundingClientRect());
        const $root = getViewportForEl(this.$host);
        if (!$root) { // window
            const windowRect = getWindowRect();
            topDistance = elementRect.top - windowRect.height;
            bottomDistance = -elementRect.bottom;
        }
        else {
            const windowRect = Rect.from($root.getBoundingClientRect());
            topDistance = elementRect.top - windowRect.bottom;
            bottomDistance = -elementRect.bottom;
        }
        return Math.max(topDistance, bottomDistance);
    }
    showAlert() {
        const distance = this.calculateDistance();
        const text = distance < 0
            ? `The element with id="${this.$host.id}" was connected to DOM when it was in viewport`
            : `The element with id="${this.$host.id}" was connected to DOM at the distance ${distance}px from the viewport`;
        const detail = {
            text,
            cls: 'alert-info',
            hideDelay: 5000
        };
        ESLEventUtils.dispatch(document.body, 'esl:alert:show', { detail });
    }
}
ESLDemoDistanceToViewportAlert.is = 'distance-to-viewport-alert';
__decorate([
    ready
], ESLDemoDistanceToViewportAlert.prototype, "connectedCallback", null);
