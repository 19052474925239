var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var ESLLazyTemplate_1;
import { ESLMixinElement } from '../../esl-mixin-element/core';
import { attr, listen, prop, memoize } from '../../esl-utils/decorators';
import { ESLIntersectionTarget, ESLIntersectionEvent } from '../../esl-event-listener/core/targets/intersection.target';
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { getViewportForEl } from '../../esl-utils/dom/scroll';
let ESLLazyTemplate = ESLLazyTemplate_1 = class ESLLazyTemplate extends ESLMixinElement {
    /** IntersectionObserver rootMargin value */
    get rootMargin() {
        return `${this.baseMargin * this.connectionRatio}px`;
    }
    /** Connection speed ratio */
    get connectionRatio() {
        var _a;
        switch ((_a = navigator.connection) === null || _a === void 0 ? void 0 : _a.effectiveType) {
            case 'slow-2g':
            case '2g': return 2;
            case '3g': return 1.5;
            case '4g':
            default: return 1;
        }
    }
    /** Host element is a template */
    get isHostTemplate() {
        return this.$host instanceof HTMLTemplateElement;
    }
    /** LazyTemplate placeholder */
    get $placeholder() {
        const placeholder = document.createElement('div');
        placeholder.className = `${ESLLazyTemplate_1.is}-placeholder`;
        this.$host.before(placeholder);
        return placeholder;
    }
    /** LazyTemplate viewport (root element for IntersectionObservers checking visibility) */
    get $viewport() {
        return getViewportForEl(this.$host);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.$placeholder.remove();
        memoize.clear(this, '$placeholder');
    }
    /** Loads content from the URL */
    loadContent(url) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch(url);
                if (!response.ok)
                    return '';
                const $template = document.createElement('template');
                $template.innerHTML = yield response.text();
                return $template.content.cloneNode(true);
            }
            catch (e) {
                return '';
            }
        });
    }
    /** Gets content from the URL or host template element */
    getContent() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.url)
                return this.loadContent(this.url);
            if (this.isHostTemplate)
                return this.$host.content.cloneNode(true);
            return '';
        });
    }
    /** Replaces host element with content */
    replaceWithContent() {
        return __awaiter(this, void 0, void 0, function* () {
            const content = yield this.getContent();
            this.$host.replaceWith(content);
        });
    }
    _onIntersect(e) {
        this.replaceWithContent();
    }
};
ESLLazyTemplate.is = 'esl-lazy-template';
__decorate([
    prop(750)
], ESLLazyTemplate.prototype, "baseMargin", void 0);
__decorate([
    prop([0, 0.01])
], ESLLazyTemplate.prototype, "INTERSECTION_THRESHOLD", void 0);
__decorate([
    attr({ name: ESLLazyTemplate.is })
], ESLLazyTemplate.prototype, "url", void 0);
__decorate([
    memoize()
], ESLLazyTemplate.prototype, "$placeholder", null);
__decorate([
    memoize()
], ESLLazyTemplate.prototype, "$viewport", null);
__decorate([
    memoize()
], ESLLazyTemplate.prototype, "loadContent", null);
__decorate([
    listen({
        event: ESLIntersectionEvent.IN,
        target: (that) => ESLIntersectionTarget.for(that.$placeholder, {
            root: that.$viewport,
            rootMargin: that.rootMargin,
            threshold: that.INTERSECTION_THRESHOLD
        })
    })
], ESLLazyTemplate.prototype, "_onIntersect", null);
ESLLazyTemplate = ESLLazyTemplate_1 = __decorate([
    ExportNs('LazyTemplate')
], ESLLazyTemplate);
export { ESLLazyTemplate };
